<template>
  <div>
      <commonHeader :current-index="3"></commonHeader>
      <div class="contation">
        <success v-if="flag"></success>
        <fail v-else></fail>
      </div>
      <commonFooter></commonFooter>
  </div>
</template>

<script> 
import { getOrderStatus } from '@/common/public.js'
import commonHeader from '@/components/commonHeader'
import success from '@/components/payment/paySuccess'
import fail from '@/components/payment/payFail'
import commonFooter from '@/components/commonFooter'
export default {
  components:{commonHeader,success,fail,commonFooter},
  data(){
      return{
          flag:true,
          sign:'',//订单号
          isBuyFlag:'',
      }
  },
  created(){
    //调用查询支付状态的接口
    this.flag = this.$route.query.flag;
    this.isBuyFlag = this.$route.query.isBuyFlag; 
    if(this.isBuyFlag && this.isBuyFlag == 'payTrue') return
    this.getOrderList()
    this.sign = this.$route.query.out_trade_no; 
  },
  mounted(){
    
  },
  methods:{
    getOrderList(){
      let data = {
        orderNo:this.sign
      };
      getOrderStatus(data).then(res=>{
        if(res.result.orderStatus ==1){
          this.flag = true;
        }else{
          this.flag = false;
        }
      }) 
    }
  }
}
</script> 

<style scoped lang="less">
  .contation{
    width: 100%;
    height: 630px;
    padding: 30px 0 50px 0;
    background: #f7f7f7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
</style>