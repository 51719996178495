<template>
    <div class="box">
        <img src="@/assets/img/paySuccess.png" alt="">
        <div class="success">支付成功！</div>
        <div class="cenText">感谢您购买视频课程，祝您学习愉快！</div>
        <div class="btnbot">
            <div class="succBtn" @click="toOrderList">返回订单</div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        toOrderList(){
            this.$router.push({
                path:'/setting',
                query:{
                    index:1
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
    .box{
        width: 1200px;
        background: #fff;
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width:100px;
            height: 100px;
        }
        .success{
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            margin-top: 35px;
        }
        .cenText{
            width: 100%;
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            color: #333333;
            margin-top: 24px;
        }
        .btnbot{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 37px;
            .succBtn{
                width: 135px;
                height: 48px;
                background: #FF8000;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 400;
                line-height: 48px;
                text-align: center;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
</style>