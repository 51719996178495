<template>
    <div class="box">
        <img src="@/assets/img/payFail.png" alt="">
        <div class="success">支付失败！</div>
        <div class="cenText">请检查网络或重新刷新支付页面！</div>
        <div class="btnbot">
            <input type="button" value="返回支付页面" @click="toBackPay">
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        toBackPay(){
            this.$router.push({
                path:'/paymentInfor'
            })
        }
    }
}
</script>

<style scoped lang="less">
    .box{
        width: 1200px;
        background: #fff;
        height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width:100px;
            height: 100px;
        }
        .success{
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            margin-top: 35px;
        }
        .cenText{
            width: 100%;
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            color: #333333;
            margin-top: 24px;
        }
        .btnbot{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 37px;
            input{
                width: 135px;
                height: 48px;
                background: #FF8000;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 400;
                line-height: 25px;
                color: #FFFFFF;
                outline: none;
                border: none;
                cursor: pointer;
            }
        }
    }
</style>